<template>
  <div class="notfound">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: '404 Not Found!'
    }
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  color: #3a90e4;
}
.notfound {
  padding: 50px 0;
}
</style>
